// "use client";
// import React, { useRef, useEffect, useState } from "react";
// import { motion } from "framer-motion";

// export const TextHoverEffect = ({ text, duration }) => {
//     const svgRef = useRef(null);
//     const [cursor, setCursor] = useState({ x: 0, y: 0 });
//     const [hovered, setHovered] = useState(false);
//     const [maskPosition, setMaskPosition] = useState({ cx: "50%", cy: "50%" });

//     useEffect(() => {
//         if (svgRef.current && cursor.x !== null && cursor.y !== null) {
//             const svgRect = svgRef.current.getBoundingClientRect();
//             const cxPercentage = ((cursor.x - svgRect.left) / svgRect.width) * 100;
//             const cyPercentage = ((cursor.y - svgRect.top) / svgRect.height) * 100;
//             setMaskPosition({
//                 cx: `${cxPercentage}%`,
//                 cy: `${cyPercentage}%`,
//             });
//         }
//     }, [cursor]);

//     return (
//         <div className="h-full w-full flex justify-center items-center">
//             <svg
//                 ref={svgRef}
//                 width="100%"
//                 height="100%"
//                 viewBox="0 0 300 100"
//                 xmlns="http://www.w3.org/2000/svg"
//                 onMouseEnter={() => setHovered(true)}
//                 onMouseLeave={() => setHovered(false)}
//                 onMouseMove={(e) => setCursor({ x: e.clientX, y: e.clientY })}
//                 className="select-none"
//                 style={{
//                     maxHeight: "200px",
//                     height: "auto",
//                 }}
//             >
//                 <defs>
//                     <linearGradient id="textGradient" gradientUnits="userSpaceOnUse" cx="50%" cy="50%" r="25%">
//                         {hovered && (
//                             <>
//                                 <stop offset="0%" stopColor="#7e22ce" /> {/* Purple */}
//                                 <stop offset="100%" stopColor="#ec4899" /> {/* Pink */}
//                             </>
//                         )}
//                     </linearGradient>

//                     <motion.radialGradient
//                         id="revealMask"
//                         gradientUnits="userSpaceOnUse"
//                         r="20%"
//                         animate={maskPosition}
//                         transition={{ duration: duration ?? 0, ease: "easeOut" }}
//                     >
//                         <stop offset="0%" stopColor="white" />
//                         <stop offset="100%" stopColor="black" />
//                     </motion.radialGradient>

//                     <mask id="textMask">
//                         <rect x="0" y="0" width="100%" height="100%" fill="url(#revealMask)" />
//                     </mask>
//                 </defs>

//                 {/* Hidden text for the hover effect */}
//                 <text
//                     x="50%"
//                     y="50%"
//                     textAnchor="middle"
//                     dominantBaseline="middle"
//                     strokeWidth="0.5"
//                     className="font-[helvetica] font-bold text-4xl sm:text-5xl md:text-6xl lg:text-7xl stroke-white"
//                     style={{ opacity: hovered ? 0.7 : 0 }}
//                 >
//                     {text}
//                 </text>

//                 {/* Main animated text */}
//                 <motion.text
//                     x="50%"
//                     y="50%"
//                     textAnchor="middle"
//                     dominantBaseline="middle"
//                     strokeWidth="0.5"
//                     className="font-[helvetica] font-bold fill-transparent text-4xl sm:text-5xl md:text-6xl lg:text-7xl stroke-white"
//                     initial={{ strokeDashoffset: 1000, strokeDasharray: 1000 }}
//                     animate={{
//                         strokeDashoffset: 0,
//                         strokeDasharray: 1000,
//                     }}
//                     transition={{
//                         duration: 4,
//                         ease: "easeInOut",
//                     }}
//                 >
//                     {text}
//                 </motion.text>

//                 {/* Gradient stroke with the mask */}
//                 <text
//                     x="50%"
//                     y="50%"
//                     textAnchor="middle"
//                     dominantBaseline="middle"
//                     stroke="url(#textGradient)"
//                     strokeWidth="0.5"
//                     mask="url(#textMask)"
//                     className="font-[helvetica] font-bold fill-transparent text-4xl sm:text-5xl md:text-6xl lg:text-7xl"
//                 >
//                     {text}
//                 </text>
//             </svg>
//         </div>
//     );
// };

// "use client";
// import React, { useRef, useEffect, useState } from "react";
// import { motion } from "framer-motion";

// export const TextHoverEffect = ({ text, duration }) => {
//     const svgRef = useRef(null);
//     const [cursor, setCursor] = useState({ x: 0, y: 0 });
//     const [hovered, setHovered] = useState(false);
//     const [maskPosition, setMaskPosition] = useState({ cx: "50%", cy: "50%" });

//     useEffect(() => {
//         if (svgRef.current && cursor.x !== null && cursor.y !== null) {
//             const svgRect = svgRef.current.getBoundingClientRect();
//             const cxPercentage = ((cursor.x - svgRect.left) / svgRect.width) * 100;
//             const cyPercentage = ((cursor.y - svgRect.top) / svgRect.height) * 100;
//             setMaskPosition({
//                 cx: `${cxPercentage}%`,
//                 cy: `${cyPercentage}%`,
//             });
//         }
//     }, [cursor]);

//     return (
//         <div className="h-full w-full flex justify-center items-center">
//             <svg
//                 ref={svgRef}
//                 width="100%"
//                 height="100%"
//                 viewBox="0 0 300 100"
//                 xmlns="http://www.w3.org/2000/svg"
//                 onMouseEnter={() => setHovered(true)}
//                 onMouseLeave={() => setHovered(false)}
//                 onMouseMove={(e) => setCursor({ x: e.clientX, y: e.clientY })}
//                 className="select-none"
//                 style={{
//                     maxHeight: "200px",
//                     height: "auto",
//                 }}
//             >
//                 <defs>
//                     <linearGradient id="textGradient" gradientUnits="userSpaceOnUse" cx="50%" cy="50%" r="25%">
//                         {hovered && (
//                             <>
//                                 <stop offset="0%" stopColor="#7e22ce" /> {/* Purple */}
//                                 <stop offset="100%" stopColor="#ec4899" /> {/* Pink */}
//                             </>
//                         )}
//                     </linearGradient>

//                     <motion.radialGradient
//                         id="revealMask"
//                         gradientUnits="userSpaceOnUse"
//                         r="20%"
//                         animate={maskPosition}
//                         transition={{ duration: duration ?? 0, ease: "easeOut" }}
//                     >
//                         <stop offset="0%" stopColor="white" />
//                         <stop offset="100%" stopColor="black" />
//                     </motion.radialGradient>

//                     <mask id="textMask">
//                         <rect x="0" y="0" width="100%" height="100%" fill="url(#revealMask)" />
//                     </mask>
//                 </defs>

//                 {/* Hidden text for the hover effect */}
//                 <text
//                     x="50%"
//                     y="50%"
//                     textAnchor="middle"
//                     dominantBaseline="middle"
//                     strokeWidth="0.5"
//                     className="font-[helvetica] font-bold text-4xl sm:text-5xl md:text-6xl lg:text-7xl stroke-white"
//                     style={{ opacity: hovered ? 0.7 : 0 }}
//                 >
//                     {text}
//                 </text>

//                 {/* Main animated text */}
//                 <motion.text
//                     x="50%"
//                     y="50%"
//                     textAnchor="middle"
//                     dominantBaseline="middle"
//                     strokeWidth="0.5"
//                     className="font-[helvetica] font-bold fill-transparent text-4xl sm:text-5xl md:text-6xl lg:text-7xl stroke-white"
//                     initial={{ strokeDashoffset: 1000, strokeDasharray: 1000 }}
//                     animate={{
//                         strokeDashoffset: 0,
//                         strokeDasharray: 1000,
//                     }}
//                     transition={{
//                         duration: 4,
//                         ease: "easeInOut",
//                     }}
//                 >
//                     {text}
//                 </motion.text>

//                 {/* Gradient stroke with the mask */}
//                 <text
//                     x="50%"
//                     y="50%"
//                     textAnchor="middle"
//                     dominantBaseline="middle"
//                     stroke="url(#textGradient)"
//                     strokeWidth="0.5"
//                     mask="url(#textMask)"
//                     className="font-[helvetica] font-bold fill-transparent text-4xl sm:text-5xl md:text-6xl lg:text-7xl"
//                 >
//                     {text}
//                 </text>
//             </svg>
//         </div>
//     );
// };



"use client";
import React from "react";
import { motion } from "framer-motion";

export const TextHoverEffect = ({ duration = 4 }) => {
    // Path data extracted from the SVG
    const paths = [
        "M339.5 339 c-11.9 -6.3 -27.3 -14.5 -31.5 -16.8 -2.5 -1.4 -8.8 -4.7 -14 -7.5 -5.2 -2.7 -12.4 -6.6 -16 -8.7 -3.6 -2.1 -8.7 -4.9 -11.5 -6.2 -11.5 -5.8 -29.2 -15.2 -31.5 -16.8 -1.4 -0.9 -6.5 -3.7 -11.5 -6.2 -4.9 -2.5 -11.2 -5.8 -14 -7.4 -2.7 -1.7 -8.6 -4.7 -13 -6.9 -4.4 -2.1 -9.5 -4.9 -11.3 -6.1 -1.8 -1.3 -3.7 -2.4 -4.3 -2.6 -0.6 -0.2 9.6 -22.6 22.7 -49.8 30.6 -64 33.5 -69.9 41.3 -85.8 l6.6 -13.3 6 12.3 c9.9 20.2 16.5 34.3 16.5 35.1 0 0.4 -1.6 4.2 -3.6 8.4 -2 4.3 -5.1 11.6 -6.9 16.3 -1.9 4.7 -5.6 13.7 -8.4 20 -2.8 6.3 -5.1 12 -5.1 12.6 0 0.6 -0.6 2.4 -1.4 4 -3.9 7.9 -8.6 18.8 -8.6 19.9 0 1.2 7.3 5 38 19.8 6.3 3 17.6 8.4 25 12 7.4 3.6 18.2 8.7 24 11.4 l10.5 5 6.1 13.4 c3.4 7.4 7.1 15.7 8.2 18.4 1.6 4 9.4 21 10.8 23.6 0.2 0.3 -0.2 0.6 -0.9 0.6 -0.7 0 -4.1 0.8 -7.7 1.7 -3.6 0.9 -7.2 1.8 -8 2 -0.8 0.2 -3.7 -0.9 -6.5 -2.4z",
        "M147 332.5 c0 -0.9 4.1 -11.1 6.7 -16.5 1.4 -3 5.2 -11.3 8.3 -18.5 11.4 -26 11.2 -25.7 12.9 -25.2 5 1.6 12.5 4.8 27.9 11.8 3.5 1.6 6.7 2.9 7.1 2.9 0.8 0 5.4 1.9 17.3 7.2 2.6 1.2 4.5 2.5 4.2 2.9 -0.2 0.4 -3 1.7 -6.2 2.9 -3.1 1.2 -10.6 4.3 -16.7 7 -13.3 5.9 -29.6 12.9 -42.5 18.1 -5.2 2.1 -11.2 4.8 -13.3 5.9 -3.8 2 -5.7 2.5 -5.7 1.5z",
    ];

    return (
        <div className="h-full w-full flex justify-center items-center">
            <svg
                width="800"
                height="800"
                viewBox="0 0 500 500"
                xmlns="http://www.w3.org/2000/svg"
                className="select-none"
            >
                {paths.map((d, index) => (
                    <motion.path
                        key={index}
                        d={d}
                        fill="none"
                        stroke="#c010e7"
                        strokeWidth="2"
                        strokeDasharray="1000" // Total path length
                        strokeDashoffset="1000" // Start hidden
                        initial={{ strokeDashoffset: 1000 }}
                        animate={{
                            strokeDashoffset: 0, // Draw the path
                            transition: {
                                duration: duration,
                                ease: "easeInOut",
                                delay: index * 0.5, // Stagger the animation for each path
                            },
                        }}
                    />
                ))}
                {paths.map((d, index) => (
                    <motion.path
                        key={`${index}-glow`}
                        d={d}
                        fill="none"
                        stroke="#f0f" // Glow color
                        strokeWidth="4" // Glow thickness
                        strokeDasharray="1000"
                        strokeDashoffset="1000"
                        initial={{ strokeDashoffset: 1000 }}
                        animate={{
                            strokeDashoffset: 0,
                            transition: {
                                duration: duration,
                                ease: "easeInOut",
                                delay: index * 0.5,
                            },
                        }}
                        className="filter blur-lg" // Apply glow effect
                    />
                ))}
            </svg>
        </div>
    );
};
