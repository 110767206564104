import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './../components/css/animation.css';

const InstagramPost = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(3); // Initially show 3 posts
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN; // Access token from environment variable

  useEffect(() => {
    console.log('Access Token:', accessToken);

    const fetchInstagramPosts = async () => {
      try {
        if (!accessToken) {
          throw new Error('Access token is missing or incorrect');
        }

        setLoading(true);

        const response = await axios.get('https://graph.instagram.com/me/media', {
          params: {
            fields: 'id,caption,media_url,permalink',
            access_token: accessToken,
            limit: 9,
          },
        });

        if (response.data && response.data.data) {
          setAllPosts(response.data.data);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching Instagram posts:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchInstagramPosts();
  }, [accessToken]);

  const handleToggleView = () => {
    setVisiblePosts((prevVisible) => (prevVisible === 3 ? allPosts.length : 3));
  };

  const handleImageClick = (permalink) => {
    // Open the post in a new tab when clicked
    window.open(permalink, '_blank');
  };

  if (loading) {
    return <div className="text-white">Gallery Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{`Error: ${error}`}</div>;
  }

  return (
    <div className="bg-[#F8F9FF] min-h-screen py-8 relative">
      <div className="py-8 text-3xl font-bold font-poppins tracking-tight text-pink-500 sm:text-2xl">
        <p className="font-lexend mx-4 mt-8 sm:mx-8 sm:ps-28 py-4">Our Stories</p>
      </div>

      <div className="mx-4 pt-8 sm:mx-9 sm:ps-24 marquee-text text-lg font-poppins text-gray-800 sm:text-xl pb-16">
        <div className="carousel-container overflow-hidden relative w-full h-full">
          <div className="flex transition-transform duration-300 ease-in-out overflow-x-auto sm:overflow-hidden">
            <div className="flex flex-nowrap gap-8">
              {allPosts.slice(0, visiblePosts).map((post) => (
                <div
                  key={post.id}
                  className="relative w-64 h-80 bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:shadow-inner-glow hover:scale-105 mb-8"
                >
                  <img
                    className="w-full h-full object-cover cursor-pointer"
                    src={post.media_url}
                    alt={post.caption}
                    onClick={() => handleImageClick(post.permalink)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramPost;
