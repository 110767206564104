// src/index.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Mainpage from './components/mainpage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/widgets/back_to_top';
import Custom_software_development from './services/Custom_software_development';
import Web_app_development from './services/web_development';
import Careers from './components/pages/careers';
import Product_management from './services/product_management';
import Contacts from './components/contact_us/contactus';
import Code_of_conduct from './components/widgets/codeofconduct';
import {TextHoverEffect} from './components/splash';
import Blog from './academy/blog';
import BlogPage from './academy/blogPage';
import Login from "./components/admin/Login";
import Dashboard from "./components/admin/Dashboard";
import AdminBlog from "./components/admin/Blog";
import Call_modal from './services/call_modle';
import Testimonials from "./components/admin/Testimonials";
import Product_development from './components/pages/product_development';
import Coustomer_engagement from './components/pages/customer_engagement';
import Operational_teams from './components/pages/operational_teams';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Ourworks from './components/ourworks';
import About_us from './components/about_us';
import Snowfall from './components/snowfall';


const App = () => {

  AOS.init({
    duration: 1000, // Duration of animation in milliseconds
    once: true, // Animation only happens once
  });

  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(true); // Add dark mode state

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the delay as needed (in milliseconds)

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <>

<div className={`bg-black ${darkMode ? 'dark' : ''}`}>
        <Router>
          <Snowfall/>
          <Routes>
            {loading ? (
              <Route index element={
                <div className="h-screen flex items-center justify-center">
                <TextHoverEffect text="ALGOARTS" 
                showLogo={true}
                showTextEffect={true}
                logoDuration={4}
                textDuration={2}/>
                </div>
              } />
            ) : (
              <Route index element={<Mainpage />} />
            )}
            <Route path="/components/mainpage" element={<Mainpage />} />
            <Route path="/services/Custom_software_development" element={<Custom_software_development />} />
            <Route path="/services/Web_app_development" element={<Web_app_development />} />
            <Route path="/services/product_management" element={<Product_management/>} />
            <Route path="/academy/blog" element={<Blog />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/our_works" element={<Ourworks/>} />
            <Route path="/about_us" element={<About_us/>} />
            <Route path="/services/call_modle" element={<Call_modal />} />
            <Route path="/Contacts" element={<Contacts />} />
            <Route path='/Code_of_conduct' element={<Code_of_conduct />} />
            <Route path="/admin" element={<Login />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/blog" element={<AdminBlog />} />
            <Route path="/academy/blog/:id" element={<BlogPage />} />
            <Route path="/customer/review/" element={<Testimonials />} />
            <Route path="/Product_development" element={<Product_development />} />
            <Route path="/customer_engagement" element={<Coustomer_engagement />} />
            <Route path="/Operational_teams" element={<Operational_teams />} />
          </Routes>
        </Router>
        <ScrollToTop />
      </div>

    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
