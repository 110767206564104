"use client"
import { useState } from 'react'
import { motion } from 'framer-motion'
import './css/content.css'
import Team from '../accets/wct.jpg'

export default function ProblemIdentification() {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <section>
      <div>
        <div className="bg-white" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/../bgimg.jpg)`,
          opacity: 0.9,
        }}>
          <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="max-w-6xl mx-auto p-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="relative overflow-hidden rounded-3xl shadow-xl bg-gradient-to-br from-pink-100 via-purple-50 to-white"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-pink-500/10 to-purple-500/10 backdrop-blur-3xl"></div>
                <div className="relative grid gap-6 p-6 md:grid-cols-2 md:gap-12 md:p-12">
                  <div className="space-y-6">
                    <div className="space-y-2">
                      <motion.h3
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className="text-xl font-semibold tracking-tight text-pink-500 md:text-2xl"
                      >
                        Problem Identification
                      </motion.h3>
                      <motion.h2
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="text-2xl font-bold tracking-tight text-purple-500 md:text-3xl lg:text-4xl"
                      >
                        Pinpointing Issues for Effective Solutions
                      </motion.h2>
                    </div>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.6 }}
                      className="text-gray-600 md:text-lg"
                    >
                      Pinpointing Issues for EffectBuilding tech and digital products can appear straightforward, yet ensuring they
                      resonate with a specific business domain and meet market demands requires a deeper understanding. Rushing into
                      development without this insight often leads to products that fall short of expectations, failing to address
                      customer needs effectively. This approach not only wastes resources but also risks damaging reputation and losing
                      market opportunities.
                    </motion.p>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.4 }}
                    >
                      <button
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        className="group inline-flex items-center text-purple-500 hover:text-purple-600 transition-colors duration-200"
                      >
                        Read more
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`ml-2 h-4 w-4 transition-transform duration-200 ${isHovered ? 'translate-x-1' : ''
                            }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </motion.div>
                  </div>
                  <div className="relative">
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5, delay: 0.5 }}
                      className="relative h-full"
                    >
                      {/* Decorative elements */}
                      <svg className="absolute right- top-4 h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                      </svg>
                      <svg className="absolute right-12 top-12 h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                      </svg>
                      <svg className="absolute right-8 top-8 h-4 w-4 text-purple-400" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg className="absolute right-16 top-16 h-3 w-3 text-purple-300" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>

                      {/* Main illustration */}
                      <div className="relative aspect-square w-full max-w-md">
                        <div className="absolute bottom-0 left-0 right-0 h-1/2 rounded-full bg-gradient-to-t from-purple-100/50"></div>
                        <div className="relative h-full w-full">
                          {/* Person illustration */}
                          <div className="absolute bottom-0 left-1/2 h-4/5 w-1/3 -translate-x-1/2">
                            <div className="h-1/4 w-full rounded-full bg-gray-800"></div> {/* Head */}
                            <div className="mt-2 h-1/2 w-full rounded-lg bg-purple-400"></div> {/* Body */}
                            <div className="mt-2 h-1/4 w-full rounded-lg bg-gray-800"></div> {/* Legs */}
                          </div>
                          {/* Device illustration */}
                          <div className="absolute bottom-0 right-0 h-4/5 w-1/3">
                            <div className="h-full w-full rounded-lg border-4 border-gray-800 bg-white"></div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}