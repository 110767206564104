import { useEffect } from 'react';

const Snowfall = () => {
  useEffect(() => {
    createSnowflakes();

    function createSnowflakes() {
      const snowflakeContainer = document.createElement('div');
      snowflakeContainer.classList.add('snowflake-container');
      document.body.appendChild(snowflakeContainer);

      for (let i = 0; i < 100; i++) {
        const snowflake = document.createElement('div');
        snowflake.classList.add('snowflake');
        snowflake.style.left = `${Math.random() * 400}vw`;
        snowflake.style.animationDuration = `${Math.random() * 3 + 2}s`; // 2-5s
        snowflake.style.animationDelay = `${Math.random() * 20}s`;
        snowflakeContainer.appendChild(snowflake);
      }
    }

    return () => {
      const snowflakeContainer = document.querySelector('.snowflake-container');
      if (snowflakeContainer) document.body.removeChild(snowflakeContainer);
    };
  }, []);

  return null;
};

export default Snowfall;
